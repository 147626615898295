import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { Box, CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

function DayButton(props) {
  const dayjs = require('dayjs');

  function elimina(event){
    event.stopPropagation();
    event.preventDefault();
    props.delete(props.index);
  }

  function dateString(){
    if(props.day.data){
      var days;
      if(props.day.data instanceof Date){
        days=dayjs().diff(dayjs(props.day.data), 'day');
      }else{
        days=dayjs().diff(dayjs(props.day.data.toDate()), 'day');
      }
      if(days==0){
        return "oggi";
      }else if(days==1){
        return "ieri";
      }else{
        return days + " giorni fa";
      }
    }else{
      return "mai fatto";
    }
  }

  return (
    <Card sx={{ minWidth: 275, m:1}}>
      <CardActionArea component="a" onClick={() => props.click({index:props.index,...props.day})}>
        <CardContent>
          <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", mb:-1}}>
            <Typography variant="h5" component="div">
                {"Giorno "+ (props.index+1)}
            </Typography>
            <IconButton aria-label="delete" onClick={elimina}>
              <DeleteIcon />
            </IconButton>
          </Box>
          <Typography variant='body1' color="text.secondary">
              {props.day.descrizione}
          </Typography>
          <Typography variant='body2' color="text.secondary">
              {props.day.esercizi.length + (props.day.esercizi.length==1?" esercizio":" esercizi")}
          </Typography>
        </CardContent>
        <CardActions>
            <Typography sx={{textAlign:"right", width:"100%",mt:-1}} variant='body2' color="text.secondary">
                {dateString()}
            </Typography>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default DayButton;
