import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddBoxIcon from '@mui/icons-material/AddBox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Library(props) {
  const [update, setUpdate] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);

  React.useEffect(() => {
    //console.log("aacac: "+props.cacca.toString());
  });

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  function addExercise(){
    props.updateLibrary([{id:"",default_set:[],massimali:null,muscoli:document.getElementById("new-ex-muscles").value,nome:document.getElementById("new-ex-name").value,set:[{reps:10,weight:0},{reps:10,weight:0},{reps:10,weight:0},{reps:10,weight:0}]}]);
    setOpenAdd(false);
  }

  const muscles = ["petto","spalle","bicipiti","tricipiti","dorso","gambe",];

  return (
    <>
    <AppBar sx={{ position: 'sticky' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={ props.toggleDrawer(true)}
          aria-label="close"
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          Libreria esercizi
        </Typography>
      </Toolbar>
    </AppBar>
    
    <List sx={{pb:10}}>
        {props.library.map(function(exercise,index){
            return(
                <ListItem disablePadding key={index}>
                  <ListItemButton>
                    <ListItemText primary={exercise.data.nome} secondary={exercise.data.muscoli}/>
                  </ListItemButton>
                </ListItem>
            )
        })}
    </List>
    <Box sx={{width:"100%", display:"flex", justifyContent:"center",position:"fixed", bottom:15}}>
        <Fab variant="extended" color='secondary' onClick={handleClickOpenAdd}>
            <AddBoxIcon sx={{ mr: 1 }} />
            Aggiungi esercizio
        </Fab>
    </Box>

    <Dialog
        fullWidth
        open={openAdd}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAdd}
        aria-describedby="alert-dialog-slide-description"
    >
    <DialogTitle sx={{mb:-1}}>Aggiungi esercizio</DialogTitle>
    <DialogContent>
        <TextField sx={{mt:1}} fullWidth id="new-ex-name" label="Nome esercizio" variant="outlined" />
        <Autocomplete
            fullWidth
            sx={{mt:1}}
            id="new-ex-muscles"
            options={muscles}
            renderInput={(params) => <TextField {...params} label="Gruppo muscolare" />}
        />
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseAdd}>Annulla</Button>
        <Button onClick={addExercise} color="secondary">Conferma</Button>
    </DialogActions>
    </Dialog>
    </>
  );
}

export default Library;
