import './App.css';
import * as React from 'react';
import Slide from '@mui/material/Slide';
import { createTheme,ThemeProvider } from '@mui/material/styles';

import Plan from './../page_plan/Plan';
import Library from './../page_library/Library';
import WKDrawer from '../WKDrawer';

import { getFirestore, doc, getDoc, collection, getDocs, updateDoc, writeBatch, query, orderBy } from "firebase/firestore";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCVhXnPJY4RjOt8TDtSQZAJTZ7cWqJMk4o",
  authDomain: "weightkeeper8.firebaseapp.com",
  projectId: "weightkeeper8",
  storageBucket: "weightkeeper8.appspot.com",
  messagingSenderId: "677421883067",
  appId: "1:677421883067:web:6a0b2f5885085c0fa3139e",
  measurementId: "G-3GNVPYLMG9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#f44336',
    },
    green: {
      main: "#90EE02",
    },
  },
});

function App() {
  const db = getFirestore(app);

  const [user, setUser] = React.useState();
  const [exLibrary, setLibrary] = React.useState();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);//-1:library | -2:settings | 0,1,2,...:plan 0,1,2,...

  React.useEffect(() => {
    if(!user){
      getUser();
    }
    if(!exLibrary){
      getExLibrary();
    }
  });

  async function getUser(){
    const docRef = doc(db, "utenti", "Z2Jg3KR5nhL6TOvae67m");
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      var u=docSnap.data();
      u.schede.forEach(scheda =>{
        scheda.giorni.forEach(giorno => {
          giorno.data=giorno.data?giorno.data.toDate():null;
        });
      });
      console.log("Converted data:", u);
      setUser(u);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  async function getExLibrary(){
    var e=[];
    const querySnapshot = await getDocs(query(collection(db, "esercizi"),orderBy("muscoli"),orderBy("nome")));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      e.push({id:doc.id,data:doc.data()});
    });
    setLibrary(e);
  }

  async function updatePlans(){
    const docRef = doc(db, "utenti", "Z2Jg3KR5nhL6TOvae67m");
    await updateDoc(docRef, {
      schede: user.schede
    });
  }

  async function addNewExToLibrary(exercisesToAdd){
    const batch = writeBatch(db);

    await exercisesToAdd.forEach((exercise) => {
      // Creates a DocRef with random ID
      const docRef = doc(collection(db, "esercizi"));

      batch.set(docRef, {
        default_set:exercise.set,
        massimali:exercise.massimali,
        muscoli:exercise.muscoli,
        nome:exercise.nome
      });
    });

    await batch.commit();

    await getExLibrary();
  }

  const toggleDrawer = (newOpen) => () => {
    setDrawerOpen(newOpen);
  };

  function openLibrary(){
    setPage(-1);
  }

  function openPlan(id){
    setPage(id);
  }

  function addPlan(){
    var nome="Scheda "+ (user.schede.length + 10).toString(36).toUpperCase();
    user.schede.push({giorni:[],nome:nome});
    updatePlans();
  }

  return (
    <ThemeProvider theme={theme}>
        {user &&
          (() => {
            switch (page) {
              case -1:
                return <Library toggleDrawer={toggleDrawer} library={exLibrary} updateLibrary={addNewExToLibrary}/>
              default:
                return <Plan key={"ca"} toggleDrawer={toggleDrawer} library={exLibrary} plans={user.schede} id={page} update={updatePlans} updateLibrary={addNewExToLibrary}/>
            }
          })()
        }
      <WKDrawer toggleDrawer={toggleDrawer} open={drawerOpen} plans={user?user.schede:[]} openLibrary={openLibrary} openPlan={openPlan} addPlan={addPlan}/>
    </ThemeProvider>
  );
}

export default App;
