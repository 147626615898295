import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import ExerciseButton from './ExerciseButton';
import ExerciseSelector from './ExerciseSelector';
import Exercise from './../page_exercise/Exercise';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Day(props) {
  const [update, setUpdate] = React.useState(false);
  const [selectedExercise, setExercise] = React.useState();
  const [selectedIndex, setIndex] = React.useState();
  const [exerciseToAdd, setExerciseToAdd] = React.useState();
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEx, setOpenEx] = React.useState(false);

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  function addExercise(){
    if(exerciseToAdd){
      if(exerciseToAdd.data){
        console.log("Add ex " + exerciseToAdd.data.nome);
        props.day.esercizi.push({id:exerciseToAdd.id,default_set:exerciseToAdd.data.default_set,massimali:exerciseToAdd.data.massimali,muscoli:exerciseToAdd.data.muscoli,nome:exerciseToAdd.data.nome,set:exerciseToAdd.data.default_set});
      }else{
        console.log("Add custom ex " + exerciseToAdd);
        props.setCustomIDs([...props.customExIDs, props.day.esercizi.push({id:"",default_set:[],massimali:null,muscoli:null,nome:exerciseToAdd,set:[]})-1]);
      }
      props.setDay(props.day);
    }
    setOpenAdd(false);
    props.updateDay();
    setUpdate(!update);
  }

  const removeExercise = (index) => {
    console.log("elimina esercizio "+index);
    props.day.esercizi.splice(index, 1);
    props.updateDay();
    setUpdate(!update);
  };
  
  const handleClickOpenEx = (index,ex) => {
    setIndex(index);
    setExercise(ex);
    setOpenEx(true);
  };

  const handleCloseEx = () => {
    props.day.esercizi[selectedIndex]=selectedExercise;
    props.updateDay();
    setOpenEx(false);
  };

  function exerciseFinished(){
    props.day.esercizi[selectedIndex]=selectedExercise;
    props.day.data=new Date();
    props.done[props.day.index][selectedIndex]=true;
    props.setDone(props.done);
    props.updateDay();
    setOpenEx(false);
  }

  return (
    <>
    <Box sx={{ minWidth: 275}}>
      {props.day.esercizi.map(function(esercizio,index){
        return(
          <ExerciseButton key={index} index={index} esercizio={esercizio} click={handleClickOpenEx} remove={removeExercise} done={props.done[props.day.index][index]}/>
        )
      })}
    </Box>
    <Box sx={{width:"100%", display:"flex", justifyContent:"center",position:"fixed", bottom:15}}>
      <Fab variant="extended" color='secondary' onClick={handleClickOpenAdd}>
        <AddCircleIcon sx={{ mr: 1 }} />
        Aggiungi esercizio
      </Fab>
    </Box>

    <Dialog
      fullWidth
      open={openAdd}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseAdd}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{mb:-1}}>Aggiungi esercizio</DialogTitle>
      <DialogContent>
        <ExerciseSelector library={props.library} setExercise={setExerciseToAdd}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAdd}>Annulla</Button>
        <Button onClick={addExercise} color="secondary">Conferma</Button>
      </DialogActions>
    </Dialog>

    <Dialog
        fullScreen
        open={openEx}
        onClose={handleCloseEx}
        TransitionComponent={Transition}
      >
        {selectedExercise&&
        <>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseEx}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedExercise.nome}
            </Typography>
          </Toolbar>
        </AppBar>
        <Exercise finished={exerciseFinished} exercise={selectedExercise} setExercise={setExercise}/>
        </>
        }
    </Dialog>
    </>
  );
}

export default Day;
