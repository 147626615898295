import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SpeedDial from '@mui/material/SpeedDial';
import EditIcon from '@mui/icons-material/Edit';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import WeightInput from './WeightInput';

function Exercise(props) {
    const [update, setUpdate] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if(activeStep == props.exercise.set.length - 1){
            props.finished();
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const repsChange = (index, value) => {
        var newEx=props.exercise;
        newEx.set[index].reps=value;
        props.setExercise(newEx);
    };

    const weightChange = (index, value) => {
        var newEx=props.exercise;
        newEx.set[index].weight=value;
        props.setExercise(newEx);
    };

    const addSet = () => {
        props.exercise.set.push({reps:1,weight:0});
        setUpdate(!update);
    };

    const removeSet = () => {
        props.exercise.set.splice(activeStep, 1);
        setActiveStep(activeStep%props.exercise.set.length);
        setUpdate(!update);
    };

    return (
        <Box sx={{ maxWidth: 400, m:1 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
            {props.exercise.set.map((s, index) => (
            <Step key={index}>
                <StepLabel
                optional={
                    index === props.exercise.set.length-1 ? (
                    <Typography variant="caption">Ultimo set</Typography>
                    ) : null
                }
                >
                {s.reps+" reps | "+s.weight+"kg"}
                </StepLabel>
                <StepContent>
                    <Box sx={{p:2}}>
                        <WeightInput sx={{mb:1}} reps index={index} value={s.reps} setValue={repsChange}/>
                        <WeightInput index={index} value={s.weight} setValue={weightChange}/>
                    </Box>
                    <Box>
                        <div>
                        <Button
                        color={index === props.exercise.set.length - 1 ? 'secondary' : 'primary'}
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            {index === props.exercise.set.length - 1 ? 'Fine esercizio' : 'Prossimo set'}
                        </Button>
                        <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            Indietro
                        </Button>
                        </div>
                    </Box>
                </StepContent>
            </Step>
            ))}
        </Stepper>
        <SpeedDial
            ariaLabel="Opzioni esercizio"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<EditIcon />}
        >
            <SpeedDialAction
                key="add"
                icon={<AddIcon/>}
                tooltipTitle="Aggiungi set"
                onClick={addSet}
            />
            <SpeedDialAction
                key="delete"
                icon={<DeleteIcon/>}
                tooltipTitle="Rimuovi set"
                onClick={removeSet}
            />
        </SpeedDial>
        </Box>
    );
}

export default Exercise;
