import * as React from 'react';
import { Typography, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';

import Logo from '../logob.png';

export default function WKDrawer(props) {

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={props.toggleDrawer(false)}>
      <Box component="img" sx={{height: "auto",   width: "100%", mb:-1}} alt="Weightkeeper" src={Logo}/>
      <List>
          <ListItem disablePadding>
            <ListItemButton onClick={props.openLibrary}>
              <ListItemIcon>
                <FitnessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Esercizi"/>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Impostazioni"/>
            </ListItemButton>
          </ListItem>
      </List>
      <Divider><Typography variant='body2'>Schede</Typography></Divider>
      <List>
          {props.plans.map(function(plan,index){
            return(
              <ListItem disablePadding key={index}>
                <ListItemButton onClick={() => props.openPlan(index)}>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={plan.nome}/>
                </ListItemButton>
              </ListItem>
            )
          })}
          <ListItem sx={{display:"flex", flexDirection:"row", justifyContent: 'center'}}>
            <Button color='secondary' variant="contained" onClick={props.addPlan}>
              Nuova scheda
            </Button>
          </ListItem>
      </List>
    </Box>
  );

  return (
    <Drawer open={props.open} onClose={props.toggleDrawer(false)}>
        {DrawerList}
    </Drawer>
  );
}