import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';

const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={props.name?0:2}
        valueIsNumericString
        suffix={props.name?(props.value==1?" rep":" reps"):" kg"}
      />
    );
  },
);

NumericFormatCustom.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

function WeightInput(props) {
  const handleChange = (event) => {
    props.setValue(props.index,event.target.value);
  };

  return (
    
      <TextField
        label={props.reps?"Ripetizioni":"Peso"}
        value={props.value}
        onChange={handleChange}
        name={props.reps}
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumericFormatCustom,
        }}
        variant="outlined"
        sx={props.sx}
        fullWidth
      />
  );
}

export default WeightInput;
