import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddBoxIcon from '@mui/icons-material/AddBox';

import DayButton from './DayButton';
import Day from './../page_day/Day';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Plan(props) {
  const [update, setUpdate] = React.useState(false);
  const [done, setDone] = React.useState([[],[],[],[],[],[],[],[],[],[]]);
  const [selectedDay, setDay] = React.useState();
  const [customExIDs, setCustomIDs] = React.useState([]);
  const [openDay, setOpenDay] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);

  React.useEffect(() => {
    //console.log("aacac: "+props.cacca.toString());
  });

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const addDay = () => {
    props.plans[props.id].giorni.push({data:null,descrizione:document.getElementById("nomegiorno").value,esercizi:[]});
    setOpenAdd(false);
    props.update();
    setUpdate(!update);
  };

  const deleteDay = (index) => {
    console.log("elimina "+index);
    props.plans[props.id].giorni.splice(index, 1);
    props.update();
    setUpdate(!update);
  };

  const updateDay = () => {
    console.log("plan id: "+props.id);
    console.log("day id: "+selectedDay.index);
    console.log(selectedDay);
    props.plans[props.id].giorni[selectedDay.index]={data:selectedDay.data?selectedDay.data:null,descrizione:selectedDay.descrizione,esercizi:[...selectedDay.esercizi]};
    //props.plans[props.id].giorni[selectedDay.index].esercizi=selectedDay.esercizi;
    props.update();
    setUpdate(!update);
  };

  const handleClickOpenDay = (d) => {
    setDay(d);
    setOpenDay(true);
  };

  const handleCloseDay = () => {
    addNewExToLibrary();//AGGIUNGE ESERCIZI NON IN LIBRERIA AL DB, DA TOGLIERE DOPO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    setOpenDay(false);
  };

  function addNewExToLibrary(){
    var exToAdd=[];
    customExIDs.forEach(customID => {
      exToAdd.push(selectedDay.esercizi[customID]);
    });
    props.updateLibrary(exToAdd);
    setCustomIDs([]);
  }

  const resetDone=()=>{
    done[selectedDay.index]=[];
    setDone(done);
    setUpdate(!update);
  }

  return (
    <>
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={ props.toggleDrawer(true)}
          aria-label="close"
        >
          <MenuIcon />
        </IconButton>
      {props.plans[props.id] &&
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          {props.plans[props.id].nome}
        </Typography>
      }
      </Toolbar>
    </AppBar>
    {props.plans[props.id] &&
    <Box sx={{ minWidth: 275}}>
      {props.plans[props.id].giorni.map(function(giorno,index){
        return(
          <DayButton key={index} index={index} day={giorno} click={handleClickOpenDay} delete={deleteDay}/>
        )
      })}
    </Box>  
    } 
    <Box sx={{width:"100%", display:"flex", justifyContent:"center",position:"fixed", bottom:15}}>
      <Fab variant="extended" color='secondary' onClick={handleClickOpenAdd}>
        <AddBoxIcon sx={{ mr: 1 }} />
        Aggiungi giorno
      </Fab>
    </Box>

    <Dialog
        open={openAdd}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseAdd}
        aria-describedby="alert-dialog-slide-description"
      >
      <DialogTitle sx={{mb:-1}}>Aggiungi nuovo giorno</DialogTitle>
      <DialogContent>
        <TextField id="nomegiorno" label="Nome giorno" variant="standard" sx={{mb:-1}}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAdd}>Annulla</Button>
        <Button onClick={addDay} color="secondary">Conferma</Button>
      </DialogActions>
    </Dialog>

    <Dialog
        fullScreen
        open={openDay}
        onClose={handleCloseDay}
        TransitionComponent={Transition}
      >
        {selectedDay &&
        <>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDay}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedDay.descrizione}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={resetDone}
              aria-label="close"
            >
              <RestartAltIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Day day={selectedDay} setDay={setDay} library={props.library} customExIDs={customExIDs} setCustomIDs={setCustomIDs} updateDay={updateDay} done={done} setDone={setDone}/>
        </>
        }
      </Dialog>
    </>
  );
}

export default Plan;
