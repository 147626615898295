import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { CardActionArea } from '@mui/material';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function ExerciseButton(props) {
    const [openDialog, setOpenDialog] = React.useState(false);

    function setsString() {
        var result="";
        if(props.esercizio.set[0]){
            var firstReps=props.esercizio.set[0].reps;
            var sameReps=true;
            props.esercizio.set.forEach(s => {
                result=result+s.reps+"-"
                if(s.reps!=firstReps){
                    sameReps=false;
                }
            });
            if(sameReps){
                result=props.esercizio.set.length + " x " +firstReps;
            }else{
                result=props.esercizio.set.length + " x " +result.slice(0, -1);
            }
        }else{
            result="Nessun set impostato"
        }
        return result;
    }

    function remove(event){
        event.stopPropagation();
        event.preventDefault();
        props.remove(props.index);
    }
    
    function handleClickOpen(event) {
        event.stopPropagation();
        event.preventDefault();
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    function setMuscle(){
        props.esercizio.muscoli=document.getElementById("combo-box-muscles").value;
        setOpenDialog(false);
    }

    const muscles = ["petto","spalle","bicipiti","tricipiti","dorso","gambe",];

    return (
    <>
        <Card sx={{ minWidth: 275, m:1 }}>
            <CardActionArea component="a" onClick={() => props.click(props.index,props.esercizio)}>
                <CardHeader
                action={
                <IconButton aria-label="delete" onClick={remove}>
                    <DeleteIcon />
                </IconButton>
                }
                title={
                <Stack alignItems="center" direction="row" gap={0.5}>
                    <Stack alignItems="left" direction="column" gap={0}>
                    <Typography variant="h5">{props.esercizio.nome}</Typography>
                    {props.esercizio.muscoli?<Typography variant="body2">{props.esercizio.muscoli}</Typography>:<Link component="button" href="#" variant="body2" onClick={handleClickOpen}>imposta gruppo muscolare</Link>}
                    </Stack>
                    {props.done&&(<DoneIcon color='green'/>)}
                </Stack>
                }
                subheader={setsString()}
                />
            </CardActionArea>
        </Card>
        <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            Scegli gruppo muscolare
            </DialogTitle>
            <DialogContent>
                <Autocomplete
                fullWidth
                sx={{mt:1}}
                id="combo-box-muscles"
                options={muscles}
                renderInput={(params) => <TextField {...params} label="Gruppo muscolare" />}
                />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Annulla</Button>
            <Button onClick={setMuscle} autoFocus>
                Ok
            </Button>
            </DialogActions>
        </Dialog>
    </>
    );
}

export default ExerciseButton;
